// import React, { useEffect } from 'react'
// import axios from 'axios'
// import { useState } from 'react'
// import logo from '../image/icon.png'
// import { FaWhatsapp } from 'react-icons/fa'

// // https://lions320dapi.emedha.in/district/get

// const District = () => {

//     const [districtData, setDistrictData] = useState([])

//     const districtShow = (route) => {
//         axios.get('https://lions320dapi.emedha.in/web/district/get', {
//             route
//         }).then((res) => {
//             setDistrictData(res.data)
//         })
//     }

//     useEffect(() => {
//         districtShow(sessionStorage.getItem('route'))
//     }, [])

//     console.log(districtData)

//     return (
//         <div className='container-fluid mb-3 mt-3' >
//             <div className='row gy-2 mb-3' >

//                 {
//                     districtData.map((intl) => {
//                         return (
//                             <div className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto mb-3'>
//                                 <div key={intl.id} className='col-lg-12 home-p-box_district pb-3 district_card_item mx-auto ' >
//                                     <center>
//                                         <img src={logo} width='140px' height='140px' className='mt-3' />
//                                     </center>
//                                     <br />
//                                     <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>
//                                     <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>{intl.clubName}</p>
//                                     <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.membershipName}</p>
//                                     <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.designation}</p>
//                                     <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.mobile}</p>
//                                     <a href={`https://api.whatsapp.com/send?phone=+91${intl.mobile}&text=Hi.`} target="_blank">
//                                         <center>
//                                             <FaWhatsapp style={{ color: 'green', fontSize: 25, textAlign: 'center' }} />
//                                         </center>
//                                     </a>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>
//         </div>
//     )
// }

// export default District


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../image/icon.png';
import { FaWhatsapp } from 'react-icons/fa';

const District = () => {
    const [districtData, setDistrictData] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState(null);

    const districtShow = (route) => {
        axios.get('https://lions320dapi.emedha.in/web/district/get', {
            params: { route }
        }).then((res) => {
            setDistrictData(res.data);
        });
    };

    useEffect(() => {
        const route = sessionStorage.getItem('route');
        districtShow(route);
        const savedDesignation = sessionStorage.getItem('selectedDesignation');
        if (savedDesignation) {
            setSelectedDesignation(savedDesignation);
        }
    }, []);

    useEffect(() => {
        // Save the selected designation to sessionStorage whenever it changes
        if (selectedDesignation) {
            sessionStorage.setItem('selectedDesignation', selectedDesignation);
        }
    }, [selectedDesignation]);

const selectedDesig=   sessionStorage.getItem('desig')
console.log(selectedDesig)

    // Filter the districtData based on selectedDesignation
    const filteredDistrictData = selectedDesig
        ? districtData.filter((item) => item.designation === selectedDesig)
        : districtData;

    const handleSelectDesignation = (designation) => {
        setSelectedDesignation(designation);
    };

    return (
        <div className='container-fluid mb-3 mt-3'>
            <div className='row gy-2 mb-3'>
                {filteredDistrictData.length > 0 ? (
                    filteredDistrictData.map((intl) => (
                        <div key={intl.id} className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto mb-3'>
                            <div className='col-lg-12 home-p-box_district pb-3 district_card_item mx-auto p-4'>
                                <center>
                                    <img src={logo} width='140px' height='110px' className='mt-3' alt='logo'/>
                                </center>
                                <br />
                                <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }}></div>
                                <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>{intl.clubName}</p>
                                <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.membershipName}</p>
                                <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.designation}</p>
                                <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.mobile}</p>
                                <a href={`https://api.whatsapp.com/send?phone=+91${intl.mobile}&text=Hi.`} target="_blank" rel="noopener noreferrer">
                                    <center>
                                        <FaWhatsapp style={{ color: 'green', fontSize: 25, textAlign: 'center' }} />
                                    </center>
                                </a>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12 text-center">
                        <p>No data available for the selected designation.</p>
                    </div>
                )}
            </div>
           
        </div>
    );
}

export default District;
