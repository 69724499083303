// import React, { useEffect, useState } from 'react'
// import axios from 'axios'
// import logo from '../image/icon.png'
// import { FaWhatsapp } from 'react-icons/fa'

// const MultipleDG = () => {

//     const [MultipleDgData, setMultipleDgData] = useState([])

//     const MultipleDgShow = () => {
//         axios.get('https://lions320dapi.emedha.in/get/multiple/DG').then((res) => {
//             setMultipleDgData(res.data)
//         })
//     }

//     useEffect(() => {
//         MultipleDgShow()
//     }, [])

//     console.log(MultipleDgData)

//     return (
//         <div className='container-fluid mt-3'>
//             <div className='row gy-3'>
//                 {
//                     MultipleDgData.map((intl) => {
//                         return (
//                             <div className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto'>
//                                 <div className='col-lg-12 home-p-box_dg pb-3 p-3 district_card_item mx-auto ' >
//                                     <center>
//                                         <img src={logo} width='150px' height="150px" className='mt-3' />
//                                     </center>
//                                     <br />
//                                     <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>
//                                     <div style={{ marginTop: 10 }}>
//                                         <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>Name: {intl.name}</p>
//                                         <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>Position: {intl.position}</p>
//                                         <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>Email: {intl.email}</p>
//                                         <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>Mobile: {intl.mobile}</p>
//                                         <a href={`https://api.whatsapp.com/send?phone=+91${intl.mobile}&text=Hi.`} target="_blank">
//                                         <center>
//                                             <FaWhatsapp style={{ color: 'green', fontSize: 25, textAlign: 'center' }} />
//                                         </center>
//                                     </a>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>
//         </div>
//     )
// }

// export default MultipleDG


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../image/icon.png';
import { FaWhatsapp } from 'react-icons/fa';

const MultipleDG = () => {
    const [multipleDgData, setMultipleDgData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedName, setSelectedName] = useState(null);

    useEffect(() => {
        axios.get('https://lions320dapi.emedha.in/get/multiple/DG')
            .then((res) => {
                setMultipleDgData(res.data);
                setFilteredData(res.data); // Set initial data
            });
    }, []);

    useEffect(() => {
        if (selectedName) {
            const filtered = multipleDgData.filter(item => item.name === selectedName);
            setFilteredData(filtered);
        } else {
            setFilteredData(multipleDgData);
        }
    }, [selectedName, multipleDgData]);

console.log(selectedName)

    return (
        <div className='container-fluid mt-3'>
            <div className='row gy-3'>
                {
                    filteredData.map((intl) => (
                        <div className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto' key={intl.id}>
                            <div className='col-lg-12 home-p-box_dg pb-3 p-3 district_card_item mx-auto'>
                                <center>
                                    <img src={logo} width='150px' height="150px" className='mt-3' />
                                </center>
                                <br />
                                <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }}></div>
                                <div style={{ marginTop: 10 }}>
                                    <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>Name: {intl.name}</p>
                                    <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>Position: {intl.position}</p>
                                    <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>Email: {intl.email}</p>
                                    <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>Mobile: {intl.mobile}</p>
                                    <a href={`https://api.whatsapp.com/send?phone=+91${intl.mobile}&text=Hi.`} target="_blank" rel="noopener noreferrer">
                                        <center>
                                            <FaWhatsapp style={{ color: 'green', fontSize: 25, textAlign: 'center' }} />
                                        </center>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default MultipleDG;
