import { Button } from '@mui/material'
import React from 'react'
import logo2 from '../../image/logo2.jpg'

const FrontEvents = () => {
    return (
        <div className='container-fluid mb-1' >
            <div className='row gy-2' >
                <div className='col-lg-4 home-p-box'>
                    <div className='col-lg-12 home-p-box pb-3 event-front-page mx-auto ' >
                        <center>
                            <img src={logo2} width='40%' className='mt-3' />
                        </center>
                        <br />
                        <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>

                        <p style={{ fontSize: '1.3rem', textAlign: 'center', color: 'rgb(44, 114, 243)', fontWeight: 'bold' }}>Gallery</p>
                        <p style={{ fontSize: '1.3rem', textAlign: 'center',color:'#966129' }}>Photo and Image</p>
                        <p style={{ fontWeight: '600', fontSize: '1.1rem', color: 'rgb(44, 114, 243)' }}>
                            What you can include in gallery descriptions. personal experiences: write about taking those photos, problems you had, the obstacles you overcame, etc.
                        </p>
                        <center>
                            <Button variant='contained' style={{backgroundColor:'#056aaf',marginTop:'5.1rem'}}>Gallery</Button>
                        </center>
                    </div>
                </div>
                <div className='col-lg-4 home-p-box'>
                    <div className='col-lg-12 home-p-box event-front-page mx-auto mb-3 pb-2'  >
                        <center>
                            <img src={logo2} width='40%' className='mt-3' />
                        </center>
                        <br />
                        <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>

                        <p style={{ fontSize: '1.3rem', textAlign: 'center', color: 'rgb(44, 114, 243)', fontWeight: 'bold' }}>Events</p>
                        <p style={{ fontSize: '1.3rem', textAlign: 'center',color:'#966129'  }}>Check More Events</p>
                        <p style={{ fontWeight: '600', fontSize: '1.1rem', color: 'rgb(44, 114, 243)' }}>
                            What is an event description? An event description is copy that aims to tell your potential attendees what will be happening at the event, who will be speaking, and what they will get out of attending. Good event descriptions can drive attendance to events and also lead to more media coverage
                        </p>
                        <center>
                            <Button variant='contained' style={{backgroundColor:'#056aaf'}}>More Events</Button>
                        </center>
                    </div>
                </div>
                <div className='col-lg-4 home-p-box'>
                    <div className='col-lg-12  home-p-box  event-front-page mx-auto mb-3 pb-2'  >
                        <center>
                            <img src={logo2} width='40%' className='mt-3' />
                        </center>
                        <br />
                        <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>

                        <p style={{ fontSize: '1.3rem', textAlign: 'center', color: 'rgb(44, 114, 243)', fontWeight: 'bold' }}>Dollars Rate</p>
                        <p style={{ fontSize: '1.3rem', textAlign: 'center',color:'#966129'  }}>Check More Dollar Rate</p>
                        <p style={{ fontWeight: '600', fontSize: '1.1rem', color: 'rgb(44, 114, 243)' }}>
                            The dollar rate is the rate at which another country's currency converts to the U.S. dollar, so it can be thought of as how many units of currency are needed to purchase 1 U.S. dollar. For example, if the dollar rate to one Canadian dollar is 1.25, then takes 1.25 Canadian dollars to buy one U.S. dollar.
                        </p>
                        <center>
                            <Button variant='contained' style={{backgroundColor:'#056aaf'}}>More Dollars</Button>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontEvents