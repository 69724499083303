import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {

    const [isVerified, setIsVerified] = React.useState(true)

    const recaptchaRef = React.createRef();

    const onChange = (value)=>{
        setIsVerified(false)
    }



    return (
        <div className='conatiner-fluid contact-container pb-3 mb-2 mt-3'>
            <div className='row'>
                <div className='col-lg-4 mx-auto contact-container' >
                    <p style={{ fontWeight: '900', color: 'gray', fontSize: '1.9rem', textAlign: 'center' }} >GET IN TOUCH</p>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { mt: 2, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="standard-basic" label="Full Name" variant="standard" />
                        <TextField id="standard-basic" label="Subject" variant="standard" />
                        <TextField id="standard-basic" label="Mobile" variant="standard" />
                        <TextField id="standard-basic" label="Email" variant="standard" />
                        <TextField
                            id="standard-multiline-static"
                            label="Message"
                            multiline
                            rows={4}
                            variant="standard"
                        />

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="visible"
                            sitekey='6Ldxq3klAAAAALW9YL-x_55lgV9orn3Lpv2D1c4r'
                            onChange={() => onChange()}
                        />

                        <Button disabled={isVerified} style={{ backgroundColor: 'red', padding: '0.6rem' }} variant="contained" >Contact</Button>
                    </Box>
                </div>
                {/* <div className='col-lg-5 mx-auto' >
                    <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="510px" id="gmap_canvas" src="https://maps.google.com/maps?q=District SecretariatPathfinder High School Near Bus stand Hanmakonda - 506001&t=&z=10&ie=UTF8&iwloc=&output=embed"></iframe> <br /></div></div>
                </div> */}
            </div>
        </div>
    );
}

export default Contact