import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Password = () => {
    return (
        <div className='col-lg-4 mx-auto password-container' >
            <p style={{ fontWeight: '900', color: 'cornflowerblue', fontSize: '1.6rem', textAlign: 'center' }} >Password</p>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { mt: 3, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="standard-basic" label="User Email" variant="standard" type='email' />
                <Button style={{ backgroundColor: 'red', padding: '0.6rem',color:'white' }} variant="contained" >Password</Button>
                <center>
                    <Link to='/login' style={{ textDecorationLine: 'none', textAlign: 'center' }}> <Button>You have already an Account? Login</Button></Link>
                </center>
            </Box>
        </div>
    );
}

export default Password