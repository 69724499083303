import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from '@mui/material/Button';
import logo from '../../image/icon.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FaCaretDown, FaFacebookF, FaInstagram, FaMailBulk, FaPhone, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import axios from 'axios'
import logo2 from '../../image/logo2.jpg'
import { Dropdown } from 'react-bootstrap';

function CollapsibleExample() {

    const actions = [
        { icon: <a href='https://www.facebook.com/lcidistrict320f' target='_blank'><FaFacebookF fontSize={23} color='gray' /></a>, name: 'Facebook' },
        { icon: <a href='https://www.instagram.com/lcidistrict320f' target='_blank'><FaInstagram fontSize={23} color='gray' /></a>, name: 'Instagram' },
        { icon: <a href='https://wa.me/919908829988?text=hi..' target='_blank'><FaWhatsapp fontSize={23} color='gray' /></a>, name: 'Twitter' },
        { icon: <a href='https://www.youtube.com/@lcidistrict320f' target='_blank'><FaYoutube fontSize={23} color='gray' /></a>, name: 'Youtube' },
    ];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open2 = Boolean(anchorEl2);
    const [selectedDistrict, setSelectedDistrict] = React.useState(null);
    const [selectedInternational, setSelectedInternational] = React.useState(null);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const navigate = useNavigate()



    const [district, setDistrict] = React.useState([])
    const [international, setInternational] = React.useState([])
    const [multiple, setMultiple] = React.useState([])
    

    const dropdown_menus_destrict = () => {
        axios.get('https://lions320dapi.emedha.in/web/district/get').then((res) => {
            setDistrict(res.data)
        })
    }

    const dropdown_menus_international = () => {
        axios.get('https://lions320dapi.emedha.in/international/get').then((res) => {
            setInternational(res.data)
        })
    }

    const dropdown_menus_multiple = () => {
        axios.get('https://lions320dapi.emedha.in/get/multiple/DG').then((res) => {
            setMultiple(res.data)
        })
    }

    React.useEffect(() => {
        dropdown_menus_destrict()
        dropdown_menus_international()
        dropdown_menus_multiple()
    }, [])

    const getUniqueDistricts = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const isDuplicate = seen.has(item.designation);
            seen.add(item.designation);
            return !isDuplicate;
        });
    };

    const getUniqueInternational = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const isDuplicate = seen.has(item.position);
            seen.add(item.position);
            return !isDuplicate;
        });
    };

    const filteredDistricts = selectedDistrict
        ? district.filter(data => data.designation === selectedDistrict)
        : getUniqueDistricts(district);

    const filteredInternational = selectedInternational
        ? international.filter(data => data.position === selectedInternational)
        : getUniqueInternational(international);

    const filteredMultiple = multiple;

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    
    const handleClose = (path, name, designation) => {
        setAnchorEl(null);
        setSelectedDistrict(name); // Update selected value
        sessionStorage.setItem('route', name);
        sessionStorage.setItem('desig', designation);

        // Navigate to the District page
        if (sessionStorage.getItem('token_key')) {
            navigate('/district-members');
            window.location.reload(); // Refresh the page to apply the filter
        } else {
            navigate('/login');
        }
    };

    function refreshPage() {
        window.location.reload(false);
    }

    const handleClose1 = (path, name) => {
        setAnchorEl1(null);
        if (sessionStorage.getItem('token_key')) {
            if (path === '/Dollar/Rate') {
                navigate('/Dollar')
            } else {
                sessionStorage.setItem('route', name)
                navigate('/international')
                refreshPage()
            }

        } else {
            navigate('/login')
        }
    };

    const handleClose2 = (name) => {
        setAnchorEl2(null);
        if (sessionStorage.getItem('token_key')) {
            navigate('/multiple-council'); // Navigate to MultipleCouncil page
            sessionStorage.setItem('route', name);
        } else {
            navigate('/login'); // Navigate to login if no token
        }
    };

    console.log(multiple)


    

    return (
        <div >
            <div className='topnav' style={{ padding: '0.4rem' }} >
                <div className='d-none d-md-flex' style={{ position: 'absolute', top: '0.4rem', right: '0.4rem', display: 'flex' }}>
                    <a href='#' >
                        <div className='icons-container' style={{ cursor: 'pointer', padding: '0.6rem', borderRadius: '30%', border: '3px solid #056aaf', justifyContent: 'center', alignItems: 'center' }}>
                            <FaFacebookF className='icons' fontSize={24} style={{ color: '#056aaf', padding: '0rem' }} />
                        </div>
                    </a>
                    <a href='#'  >
                        <div className='ms-2 icons-container' style={{ cursor: 'pointer', padding: '0.6rem', borderRadius: '30%', border: '3px solid #056aaf', justifyContent: 'center', alignItems: 'center' }}>
                            <FaInstagram className='icons' fontSize={24} style={{ color: '#056aaf' }} />
                        </div>
                    </a>
                    <a href='#'>
                        <div className='ms-2 icons-container' style={{ cursor: 'pointer', padding: '0.6rem', borderRadius: '30%', border: '3px solid #056aaf', justifyContent: 'center', alignItems: 'center' }}>
                            <FaYoutube className='icons' fontSize={24} style={{ color: '#056aaf' }} />
                        </div>
                    </a>
                    <a href='https://wa.me/+919866108933?text=hi..'>
                        <div className='ms-2 icons-container' style={{ cursor: 'pointer', padding: '0.6rem', borderRadius: '30%', border: '3px solid #056aaf', justifyContent: 'center', alignItems: 'center' }}>
                            <FaWhatsapp className='icons' fontSize={24} style={{ color: '#056aaf' }} />
                        </div>
                    </a>
                </div>
                <div className='d-block d-md-flex mt-2' >
                    <div>
                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#056aaf', marginRight: '2rem' }}><FaPhone /> +91  9866108933</p>
                    </div>
                    <div>
                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#056aaf' }}><FaMailBulk /> district320d@gmail.com</p>
                    </div>
                </div>
            </div>
            <Navbar className='mainnavcon' collapseOnSelect expand="xl" bg="white" variant="white">
                <Container fluid >
                    <div className='d-none d-xl-block'>
                        <img src={logo2} style={{ width: '60px', marginLeft: '0rem' }} />
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            <Link style={{ textDecorationLine: 'none' }} to='/'>  <Button
                                style={{ fontWeight: 'bold', color: '#056aaf' }}
                            >
                                Home
                            </Button></Link>
                            <Link style={{ textDecoration: 'none' }}>
                                <Button
                                    style={{ fontWeight: 'bold', color: '#056aaf' }}
                                >
                                    About
                                </Button>
                            </Link>
                            <div>
                                <Link style={{ textDecoration: 'none' }}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        style={{ fontWeight: 'bold', color: '#056aaf' }}
                                    >
                                        DISTRICT <FaCaretDown />
                                    </Button></Link>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <div style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>
                                        {filteredDistricts.map((data) => (
                                            <MenuItem
                                                key={data.designation} // Add a unique key for each MenuItem
                                                style={{ marginTop: '0.3rem' }}
                                                onClick={() => handleClose(data.path, data.membershipName, data.designation)}
                                            >
                                                {data.designation}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Menu>


                            </div>
                            <div>
                                <Link style={{ textDecoration: 'none' }}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open1 ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open1 ? 'true' : undefined}
                                        onClick={handleClick1}
                                        style={{ fontWeight: 'bold', color: '#056aaf' }}
                                    >
                                        International <FaCaretDown />
                                    </Button></Link>
                                <Menu
                                    id="basic-menu1"
                                    anchorEl={anchorEl1}
                                    open={Boolean(anchorEl1)}
                                    onClose={() => setAnchorEl1(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button1',
                                    }}
                                >
                                    <div style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>
                                        {filteredInternational.map((data) => (
                                            <MenuItem
                                                key={data.position} // Add a unique key for each MenuItem
                                                style={{ marginTop: '0.3rem' }}
                                                onClick={() => handleClose1(data.path, data.position)}
                                            >
                                                {data.position}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Menu>

                            </div>
                            <div>
                                {/* <Link style={{ textDecoration: 'none' }}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick2}
                                        style={{ fontWeight: 'bold', color: '#056aaf' }}
                                    >
                                        Multiple <FaCaretDown />
                                    </Button>
                                </Link> */}

                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="link"
                                        style={{ fontWeight: 'bold', color: '#056aaf',textDecoration:'none'}}
                                    >
                                        MULTIPLE
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                    <Dropdown.Item href="/multiple-council">MultipleCouncil</Dropdown.Item>
                                        <Dropdown.Item href="/multiple-dg">MultipleDG</Dropdown.Item>                                       
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl2}
                                    open={open2}
                                    onClose={handleClose2}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <div style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
                                        {
                                            multiple.map((data) => {
                                                return (
                                                    <MenuItem style={{ marginTop: '0.3rem' }} onClick={() => handleClose2(data.path)} > {data.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </div>
                                </Menu> */}
                            </div>
                            <Link to='/member' style={{ textDecoration: 'none' }}>
                                <Button
                                    style={{ fontWeight: 'bold', color: '#056aaf' }}

                                >
                                    Member
                                </Button></Link>
                            <Link to='/club' style={{ textDecoration: 'none' }}>
                                <Button
                                    style={{ fontWeight: 'bold', color: '#056aaf' }}
                                >
                                    Club
                                </Button>
                            </Link>
                            <Link style={{ textDecoration: 'none' }}>
                                <Button
                                    style={{ fontWeight: 'bold', color: '#056aaf' }}
                                >
                                    Download
                                </Button></Link>
                            <Link to='/login' style={{ textDecorationLine: 'none' }}> <Button variant='contained' style={{ backgroundColor: 'white', color: '#056aaf', borderRadius: '0.5rem', width: '10rem' }}>Login/Register</Button></Link>
                            <Link className='ms-xl-2 mt-xl-0 mt-2' style={{ textDecorationLine: 'none' }}> <Button variant='contained' style={{ backgroundColor: 'white', color: '#056aaf', borderRadius: '0.5rem', width: '10rem' }}>Contact</Button></Link>
                        </Nav>
                        <Nav>
                            {
                                sessionStorage.getItem('token_key') && <Button onClick={() => {
                                    sessionStorage.removeItem('token_key')
                                    navigate('/login')
                                }} variant='contained' style={{ backgroundColor: 'white', color: '#056aaf', borderRadius: '0.5rem', marginLeft: '0.3rem', marginRight: '1rem' }}>Logout</Button>
                            }
                        </Nav>
                    </Navbar.Collapse>
                    <img src={logo} style={{ width: '60px' }} />
                </Container>
            </Navbar>
            <div className='d-block d-md-none' style={{ position: 'fixed', bottom: '0.9rem', right: '0.4rem', zIndex: 100 }}>
                <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        icon={<SpeedDialIcon />}
                    >
                        {actions.map((action) => (

                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                            />

                        ))}
                    </SpeedDial>
                </Box>
            </div>
        </div>
    );
}

export default CollapsibleExample;