// import Carousel from 'react-bootstrap/Carousel';
// import f2 from '../../image/Slide5.PNG'
// import f1 from '../../image/Slide59.PNG'
// import f3 from '../../image/Slide2.PNG'
// import f4 from '../../image/Slide3.PNG'
// import f5 from '../../image/Slide4.PNG'


// function Caraousel() {
//   return (
//     <Carousel>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src={f1}
//           alt="Third slide"
//           style={{opacity:0.7}}
//         />

        
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src={f2}
//           alt="Third slide"
//           style={{opacity:0.7}}
//         />

        
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src={f3}
//           alt="Third slide"
//           style={{opacity:0.7}}
//         />

        
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src={f4}
//           alt="Third slide"
//           style={{opacity:0.7}}
//         />

        
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src={f5}
//           alt="Third slide"
//           style={{opacity:0.7}}
//         />

        
//       </Carousel.Item>
//     </Carousel>
//   );
// }

// export default Caraousel;


import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import f2 from '../../image/Slide2.PNG'
import f1 from '../../image/Slide1.PNG'
import f3 from '../../image/Slide3.PNG'
import f4 from '../../image/Slide4.PNG'
import f5 from '../../image/Slide5.PNG'
import f6 from '../../image/Slide6.PNG'
import f7 from '../../image/Slide7.PNG'
import f8 from '../../image/Slide8.PNG'
import f9 from '../../image/Slide9.PNG'
import f10 from '../../image/Slide10.PNG'
import f11 from '../../image/Slide11.PNG'
import f12 from '../../image/Slide12.PNG'
import f13 from '../../image/Slide13.PNG'
import f14 from '../../image/Slide14.PNG'
import f15 from '../../image/Slide15.PNG'
import f16 from '../../image/Slide16.PNG'
import f17 from '../../image/Slide17.PNG'
import f18 from '../../image/Slide18.PNG'
import f19 from '../../image/Slide19.PNG'
import f20 from '../../image/Slide20.PNG'
import f21 from '../../image/Slide21.PNG'
import f22 from '../../image/Slide22.PNG'
import f23 from '../../image/Slide23.PNG'
import f24 from '../../image/Slide24.PNG'



const images = [f1, f2, f3, f4, f5, f6, f7, f8, f9, f10, f11, f12, f13, f14, f15, f16, f17, f18, f19, f20, f21, f22, f23, f24 /* ... add other image variables up to f25 */];

const CarouselComponent = () => (
  <Carousel>
    {images.map((image, index) => (
      <Carousel.Item key={index}>
        <img
          className="d-block w-100"
          src={image}
          alt={`Slide ${index + 1}`}
          style={{ opacity: 0.7 }}
        />
      </Carousel.Item>
    ))}
  </Carousel>
);

export default CarouselComponent;
