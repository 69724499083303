import React from 'react'
import { FaLocationArrow, FaMailBulk, FaPhone } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='footer' >

      <div style={{ background: 'white', width: '100%',paddingTop:'2.0rem',paddingBottom:'5rem' }} >
        <center>
          <div style={{ width: '50%' }}>
            <p style={{ textAlign: 'center', position: 'relative', top: '2.4rem' }} >      </p>
          </div>
        </center>
      </div>
      <div className='ps-4 pt-3'>
        <p style={{ fontSize: '1.1rem', color: '#056aaf', fontWeight: 'bold' }}><FaPhone />  </p>
        <p style={{ fontSize: '1.1rem', color: '#056aaf', fontWeight: 'bold' }}><FaMailBulk />  </p>
        <p style={{ fontSize: '1.1rem', color: '#056aaf', fontWeight: 'bold' }}><FaLocationArrow />  
          </p>
      </div>
      <p className='text-center mt-5' style={{ fontSize: '1rem', color: '#056aaf' }}> @2023 Website Develope And maintained by Emedha Technologies </p>
    </div>
  )
}

export default Footer