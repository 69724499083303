import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [club, setClub] = React.useState('');
    const [clubName, setClubName] = React.useState([])

    const [membershipNo, setMembershipNo] = useState('')
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const navigate = useNavigate()

    const signup = (select = club) => {
        axios.post('https://lions320dapi.emedha.in/signup/post', {
            mobile,
            membershipNo,
            password,
            select
        }).then(async (res) => {
            if (res.data === "Signup Successfull !..") {
                alert(res.data)
                navigate('/login')
            } else {
                alert(res.data)
            }

        })
    }


    const clubNameItem = async () => {
        const res = await axios.get('https://lions320dapi.emedha.in/clubName/get')
        setClubName(res.data)
    }

    React.useEffect(() => {
        clubNameItem()
    }, [])

    const handleChange = (event) => {
        setClub(event.target.value);
    };
    return (
        <div className='pb-2'>
            <div className='col-lg-4 mx-auto signup-container mb-5' >
                <p style={{ fontWeight: '900', color: 'cornflowerblue', fontSize: '1.6rem', textAlign: 'center' }} >Signup</p>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { mt: 2, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField id="standard-basic" label="Mobile" variant="standard" onChange={(e) => setMobile(e.target.value)} />
                    <TextField id="standard-basic" label="Membership Number" variant="standard" onChange={(e) => setMembershipNo(e.target.value)} />

                    <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                        <InputLabel id="demo-simple-select-standard-label">Select Club Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={club}
                            onChange={handleChange}
                            label="Select Club Name"
                        >
                            {
                                clubName.map((data) => {
                                    return (
                                        <MenuItem value={data.clubName}>{data.clubName}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <TextField id="standard-basic" label="Password" variant="standard" type="password" onChange={(e) => setPassword(e.target.value)} />
                    <TextField id="standard-basic" label="Confirm Password" type='password' variant="standard" onChange={(e) => setConfirmPassword(e.target.value)} />
                    <Button style={{ backgroundColor: 'red', padding: '0.6rem',color:'white' }} variant="contained" onClick={() => signup()} >Signup</Button>
                    <center>
                        <Link to='/password-email-validation'><Button style={{ textDecoration: 'underline',color:'red' }}>You forgot your password or reset your password? Password</Button></Link>
                    </center>
                    <center>
                        <Link to='/login' style={{ textDecorationLine: 'none', textAlign: 'center',color:'red' }}> <Button>You have already an Account? Login</Button></Link>
                    </center>
                </Box>
            </div>
        </div>
    );
}

export default Signup