import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

const Login = () => {

    const navigate = useNavigate()

    const [mobile, setMobile] = useState()
    const [password, setPassword] = useState()

    // https://lions320dapi.emedha.in/login

    let token = `login_token_${new Date()}_321c2_web_app`

    // const loginPost = () => {

    //     axios.post('http://localhost:4005/login', {

    //         mobile,
    //         password,

    //     }).then((res) => {
    //         console.log(res.data)
    //         if (res.data === "Login Succcessful !..") {
    //             alert(res.data)
    //             sessionStorage.setItem('loginEmail', mobile);
    //             sessionStorage.setItem('token_key', token)
    //             navigate('/')
    //         } else {
    //             alert('Please Enter valid Data..!')
    //         }
    //     })
    // }

    const loginPost = () => {
        axios.post('https://lions320dapi.emedha.in/web-login', {
            mobile,
            password,
        }).then((res) => {
            console.log('Response Data:', res.data); // Log the response data
            
            if (res.data === "Login Successful!") {
                alert(res.data);
                sessionStorage.setItem('loginEmail', mobile);
                sessionStorage.setItem('token_key', token);
                navigate('/');
            } else {
                alert('Please Enter valid Data..!');
            }
        }).catch((error) => {
            console.error('API error:', error); // Log any error during the API call
        });
    }
    

    return (
        <div className='col-lg-4 mx-auto login-container mb-5' >
            <p style={{ fontWeight: '900', color: 'cornflowerblue', fontSize: '1.6rem', textAlign: 'center' }} >LOGIN</p>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { mt: 3, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="standard-basic" label="User Mobile Number" variant="standard" onChange={(e) => setMobile(e.target.value)} />
                <TextField id="standard-basic" type='password' label="Password" variant="standard" onChange={(e) => setPassword(e.target.value)} />
                <Button style={{ backgroundColor: 'red', padding: '0.6rem', color: 'white' }} variant="contained" onClick={() => loginPost()} >Login</Button>
                <center>
                    <Link to='/password-email-validation'><Button style={{ textDecoration: 'underline', color: 'red' }}>You forgot your password or reset your password? Password</Button></Link>
                </center>
                <center>
                    <Link to='/signup' style={{ textDecorationLine: 'none', textAlign: 'center', color: 'red' }}> <Button>You have no any Account? Register</Button></Link>
                </center>
            </Box>
        </div>
    );
}

export default Login