// import axios from 'axios'
// import logo from '../image/icon.png'
// import React, { useEffect, useState } from 'react'

// const MultipleCouncil = () => {

//     const [multiple, setMultiple] = useState([])

//     const MultipleCouncileShow = () => {
//         axios.get('https://lions320dapi.emedha.in/get/multiple/council').then((res) => {
//             setMultiple(res.data)
//         })
//     }

//     useEffect(() => {
//         MultipleCouncileShow()
//     }, [])

//     return (
//         <div className='container-fluid mt-3'>
//             <div className='row gy-3'>
//                 {
//                     multiple.map((intl) => {
//                         return (
//                             <div className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto'>
//                                 <div className='col-lg-12 home-p-box_dg pb-3 p-3 district_card_item mx-auto ' >
//                                     <center>
//                                         <img src={logo} width='150px' height="150px" className='mt-3' />
//                                     </center>
//                                     <br />
//                                     <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>
//                                     <div style={{ marginTop: 20 }}>
//                                         <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>Name: {intl.name}</p>
//                                         <p style={{ fontSize: 17, alignItems: 'center' }}>Position: {intl.position}</p>
//                                         <p style={{ fontSize: 17, alignItems: 'center' }}>Email: {intl.email}</p>
//                                         <p style={{ fontSize: 17, alignItems: 'center' }}>Mobile: {intl.mobile}</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }

//             </div>
//         </div>
//     )
// }

// export default MultipleCouncil



import axios from 'axios';
import logo from '../image/icon.png';
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const MultipleCouncil = () => {
    const [multiple, setMultiple] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchMultipleCouncilData = async () => {
        try {
            const response = await axios.get('https://lions320dapi.emedha.in/get/multiple/council');
            setMultiple(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to load data');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMultipleCouncilData();
    }, []);

    if (loading) {
        return <div className='container-fluid mt-3'>Loading...</div>;
    }

    if (error) {
        return <div className='container-fluid mt-3'>{error}</div>;
    }

    return (
        <div className='container-fluid mt-3'>
            <div className='row gy-3'>
                {multiple.length > 0 ? (
                    multiple.map((item) => (
                        <div key={item.id} className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto'>
                            <div className='col-lg-12 home-p-box_dg pb-3 p-3 district_card_item mx-auto'>
                                <center>
                                    <img src={logo} width='150px' height='150px' className='mt-3' alt='logo' />
                                </center>
                                <br />
                                <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }}></div>
                                <div style={{ marginTop: 20 }}>
                                    <p style={{ fontSize: 20, fontWeight: 'bold' }}>Name: {item.name}</p>
                                    <p style={{ fontSize: 17 }}>Position: {item.position}</p>
                                    <p style={{ fontSize: 17 }}>Email: {item.email}</p>
                                    <p style={{ fontSize: 17 }}>Mobile: {item.mobile}</p>
                                    <a href={`https://api.whatsapp.com/send?phone=+91${item.mobile}&text=Hi.`} target="_blank">
                                        <center>
                                            <FaWhatsapp style={{ color: 'green', fontSize: 25, textAlign: 'center' }} />
                                        </center>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='container-fluid mt-3'>No data available</div>
                )}
            </div>
        </div>
    );
};

export default MultipleCouncil;
