import React from 'react'
import { Button } from '@mui/material'
import Contact from './Contact'
import FrontEvents from './FrontEvents'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Caraousel from './Carausel'

const Home = () => {
    return (
        <div className='mb-4'>
            <div className='home-page container-fluid mx-auto mb-1' >
                <div className='row' >
                    <div className='col-lg-5 mx-auto' >
                        <div style={{ marginTop: '2rem' }}>
                            <p style={{ textAlign: 'center', fontWeight: '900', color: '#056aaf', fontSize: '3rem' }}>
                                <span style={{ fontSize: '6rem', textTransform: 'lowercase' }} >W</span>elcome to district 320D
                            </p>
                            <p style={{ fontSize: '1.2rem', textAlign: 'center' }} >Consist of Adilabad, Nizamabad, Medak, RR and Hyderabad Thorrur there are around 4000+ members with 144+ Clubs . There are many signature projects, permeant projects in this district. </p>
                            <br />
                            <center>
                                <Link to='/signup'><button className='regBtn' >REGISTER</button></Link>
                            </center>
                        </div>
                    </div>
                    <div className='col-lg-6 mx-auto carausel' >
                        <Caraousel />
                    </div>
                </div>
            </div>
            <FrontEvents />
            <Contact />
            <Footer />

        </div>
    )
}

export default Home