import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWindowClose } from 'react-icons/fa';

const Club = () => {

    const [select, setSelect] = useState('Select Club')
    const [isClicked, setIsClicked] = useState(false)
    const [clubName, setClubName] = useState([])

    const [club, setClub] = React.useState('');


    const clubNameShow = async () => {
        const res = await axios.get('https://lions320dapi.emedha.in/clubName/get')
        setClubName(res.data)
    }

    const selectedClubMember = (search) => {
        setSelect(search)
        setIsClicked(false)
        setShowMember(true)
        setSelect(search)
        axios.post('https://lions320dapi.emedha.in/search/club', {
            search
        }).then((resp) => setClubName(resp.data))
    }

    const [showMember, setShowMember] = useState(false)

    const search = (name) => {
        axios.post('https://lions320dapi.emedha.in/search/club', {
            name
        }).then((resp) => setClubName(resp.data))
    }

    const clubNameItem = async () => {
        const res = await axios.get('https://lions320dapi.emedha.in/clubName/get')
        setClubName(res.data)
    }

    useEffect(() => {
        clubNameShow()
    }, [])

    const handleChange = (event) => {
        setClub(event.target.value);
        selectedClubMember(event.target.value)
    };

    return (
        <div className='p-1'>
            {
                !showMember && (
                    <div className='member-search container-fluid pt-4'>
                        <div>
                            <center>
                                <FormControl variant="standard" sx={{ minWidth: '60%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Select Club Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={club}
                                        onChange={handleChange}
                                        label="Select Club Name"
                                    >
                                        {
                                            clubName.map((data) => {
                                                return (
                                                    <MenuItem value={data.clubName}>{data.clubName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </center>
                        </div>
                        <div>
                            <p style={{ textAlign: 'center', marginTop: '15rem', fontWeight: 'bold' }}>Select Club To Show Member Contact</p>
                        </div>
                    </div>
                )
            }
            {
                showMember && (
                    <div>
                        <div className='member-heading p-2 mt-1'  >
                            <p style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', position: 'relative', top: '0.5rem' }}>
                                {club}
                            </p>
                            <FaWindowClose onClick={() => {
                                setShowMember(false)
                                clubNameShow()
                                setClub('')
                            }} fontSize={34} style={{ position: 'absolute', top: '0.92rem', left: '0.4rem', cursor: 'pointer' }} />
                        </div>
                        <div className='club-data-container mt-2'>
                            <div className='col-lg-6 col-md-8 col-10 mx-auto '>
                                {
                                    clubName.map((member, index) => {
                                        return (
                                            <div key={member.clubName}>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p  >Club Member</p>
                                                    <p  >{member.clubNumber}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Club Name</p>
                                                    <p >{member.clubName}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Charter President</p>
                                                    <p >{member.charterPresident}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Secretory</p>
                                                    <p >{member.secretory}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Treasurer</p>
                                                    <p >{member.treasurer}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Charted on</p>
                                                    <p >{member.chartedOn}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Extn Chairperson</p>
                                                    <p >{member.extnChairperson}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Region Chairperson</p>
                                                    <p >{member.regionChairperson}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Zone Chairperson</p>
                                                    <p >{member.zoneChairperson}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Total Members</p>
                                                    <p >{member.totalMembers}</p>
                                                </div>
                                                <div className='d-flex justify-content-between' style={{ marginTop: 10, borderBottomColor: 'rgb(14, 57, 114)', borderBottomWidth: 0.7, justifyContent: 'center', height: 35, marginLeft: 10, marginRight: 10, flexDirection: 'row' }}  >
                                                    <p >Sponsored By</p>
                                                    <p >{member.clubSponsoredBy}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Club