import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { FaDotCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function MemberDashboard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='col-lg-7 mx-auto acoordian mb-4' >
            <Accordion style={{ padding: '1rem' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        International
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>International Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> International President </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Dollar Rate </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Immeidate Past Intl President </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> 1st Intl Vice President </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> 2nd Intl Vice President </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> 3rd Intl Vice President </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> International Directors</p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Past Intl President-india</p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Past Intl Director-India </p></Link>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ padding: '1rem' }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>District</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        District Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Link style={{ textDecoration: 'none' }} ><p></p></Link>
                        <div>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> District Governor</p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Events </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> DG Diary </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> IPDG </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> 1st VDG </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> 2nd VDG </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Honorary Committee </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Key Team Member </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Pride Of District </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Micro Cabinet </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> District Co-Ordinetors </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Chief Secretary </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Spacial Secretay </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> District Chairperson </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Region Chairperson </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Zone Chairperson </p></Link>

                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Chief Co-Ordinator </p></Link>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ padding: '1rem' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Multiple
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Multiple Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div >
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Council Officers </p></Link>
                            <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> DG Team&PDG's Of MD320 </p></Link>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ padding: '1rem' }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Club</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Club Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Clubs </p></Link>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ padding: '1rem' }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Members</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Members Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Link style={{ textDecoration: 'none' }} ><p style={{ marginTop: '0.3rem', fontWeight: 'bold', color: 'rgb(44, 114, 243)', borderBottom: '1px solid lightgray' }} > <FaDotCircle fontSize={11} style={{ position: 'relative', top: '-0.17rem' }} /> Members </p></Link>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}