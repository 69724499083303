import React, { useEffect } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import './App.css';
import Login from './Container/Component/Auth/Login/Login';
import Navigation from './Container/Component/Navigation/Navigation';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Signup from './Container/Component/Auth/Signup/Signup';
import Password from './Container/Component/Auth/password/Password';
import Home from './Container/Component/WebPages/Home';
import MemberDashboard from './Container/Component/MemberDashboard/MemberDashboard';
import axios from 'axios';
import District from './Container/District/District';
import MultipleCouncil from './Container/Multiple/MultipleCouncil';
import MultipleDG from './Container/Multiple/MultipleDG';
import Member from './Container/Component/WebPages/Member';
import Club from './Container/Component/WebPages/Club';
import DollarRate from './Container/Component/WebPages/DollarRate';
import International from './Container/International/International';

function App() {

  const [district, setDistrict] = React.useState([])
  const [international, setInternational] = React.useState([])
  const [multiple, setMultiple] = React.useState([])


  const dropdown_menus_destrict = () => {
    axios.get('https://lions320dapi.emedha.in/web/district/get').then((res) => {
      setDistrict(res.data)
    })
  }

  const dropdown_menus_international = () => {
    axios.get('https://lions320dapi.emedha.in/international/get').then((res) => {
      setInternational(res.data)
    })
  }

  const dropdown_menus_multiple = () => {
    axios.get('https://lions320dapi.emedha.in/get/multiple/DG').then((res) => {
      setMultiple(res.data)
    })
  }

  useEffect(() => {
    dropdown_menus_destrict()
    dropdown_menus_multiple()
    dropdown_menus_international()
  }, [])

  return (
    <div className='ps-2 pe-2' >
      <BrowserRouter>
        <Navigation />
        <div>
          <Routes>
            <Route path='/district-members' element={<District />} />
            <Route path='/international' element={<International />} />
            <Route path='/login' element={<Login />} />
            <Route path='/club' element={<Club />} />
            <Route path='/Dollar' element={<DollarRate />} />
            <Route path='/menu-dashboard' element={<MemberDashboard />} />
            <Route path='/member' element={<Member />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/password-email-validation' element={<Password />} />
            <Route path='/' element={<Home />} />
            <Route path="/Council/Officers" element={<MultipleCouncil />} />
            <Route path="/multiple-council" element={<MultipleDG />} />
          </Routes>
        </div>
      </BrowserRouter>

    </div>
  );
}

export default App;
