import React, { useEffect, useState } from 'react'
import axios from 'axios'
import logo from '../../image/icon.png'
import { FaDollarSign } from 'react-icons/fa'

const DollarRate = () => {

    const [dollar, setDollar] = useState([])

    const DollarData = async () => {
        const res = await axios.get(`https://lions320dapi.emedha.in/dollar/get`, {
        })
        setDollar(res.data)
    }

    useEffect(() => {
        DollarData()
    })

    return (
        <div className='container-fluid' >
            <div className='row justify-content-center mt-2' >
                {
                    dollar.map((data, index) => {
                        return (
                            <div className='col-lg-3 col-12 col-xl-2 col-xxl- col-md-6'>
                                <div className='col-lg-12 home-p-box_member pb-3 p-3 district_card_item mx-auto ' >
                                    <center>
                                        <FaDollarSign fontSize={69} color="rgb(44, 114, 243)" />
                                    </center>
                                    <br />
                                    <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>
                                    <br />
                                    <div key={index} >
                                        <div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Date Month</p>
                                                <p>{data.dmonth}</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Date Year</p>
                                                <p>{data.dyear}</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Date Rate</p>
                                                <p>{data.drate}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default DollarRate