import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../image/icon.png'
import moment from 'moment'
import { FaWhatsapp, FaWindowClose } from 'react-icons/fa';

const Member = () => {

    const [select, setSelect] = useState('Select Club')
    const [isClicked, setIsClicked] = useState(false)
    const [clubName, setClubMembers] = useState([])

    const [club, setClub] = React.useState('');


    const clubNameShow = async () => {
        const res = await axios.get('https://lions320dapi.emedha.in/clubName/get')
        setClubMembers(res.data)
    }

    const selectedClubMember = (name) => {
        setSelect(name)
        setIsClicked(false)
        setShowMember(true)
        setSelect(name)
        axios.post('https://lions320dapi.emedha.in/search/member', {
            name
        }).then((resp) => setClubMembers(resp.data))
    }

    const [showMember, setShowMember] = useState(false)

    const search = (name) => {
        axios.post('https://lions320dapi.emedha.in/search/club', {
            name
        }).then((resp) => console.log(resp.data))
    }

    const clubNameItem = async () => {
        const res = await axios.get('https://lions320dapi.emedha.in/clubName/get')
        setClubMembers(res.data)
    }

    useEffect(() => {
        clubNameShow()
    }, [])

    const handleChange = (event) => {
        setClub(event.target.value);
        selectedClubMember(event.target.value)
    };

    return (
        <div className='p-1'>
            {
                !showMember && (
                    <div className='member-search container-fluid pt-4'>
                        <div>
                            <center>
                                <FormControl variant="standard" sx={{ minWidth: '60%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Select Club Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={club}
                                        onChange={handleChange}
                                        label="Select Club Name"
                                    >
                                        {
                                            clubName.map((data) => {
                                                return (
                                                    <MenuItem value={data.clubName}>{data.clubName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </center>
                        </div>
                        <div>
                            <p style={{ textAlign: 'center', marginTop: '15rem', fontWeight: 'bold' }}>Select Club To Show Members</p>
                        </div>
                    </div>
                )
            }
            {
                showMember && (
                    <div className='row gy-3 p-2'>

                        <div className='member-heading pt-3'  >
                            <p style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                                {club}
                            </p>
                            <FaWindowClose onClick={() => {
                                setShowMember(false)
                                clubNameShow()
                                setClub('')
                            }} fontSize={34} style={{ position: 'absolute', top: '0.92rem', left: '0.4rem', cursor: 'pointer' }} />
                        </div>
                        {
                            clubName.map((member, index) => {
                                let MarriageMonth = ""
                                let BirthdayMonth = ""

                                if (moment(member.marriageAnniversary).month() + 1 === 1) {
                                    MarriageMonth = 'Jan'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 2) {
                                    MarriageMonth = 'Feb'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 3) {
                                    MarriageMonth = 'Mar'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 4) {
                                    MarriageMonth = 'Apr'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 5) {
                                    MarriageMonth = 'May'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 6) {
                                    MarriageMonth = 'Jun'
                                }

                                if (moment(member.marriageAnniversary).month() + 1 === 7) {
                                    MarriageMonth = 'Jul'
                                }

                                if (moment(member.marriageAnniversary).month() + 1 === 8) {
                                    MarriageMonth = 'Aug'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 9) {
                                    MarriageMonth = 'Sep'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 10) {
                                    MarriageMonth = 'Oct'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 11) {
                                    MarriageMonth = 'Nov'
                                }
                                if (moment(member.marriageAnniversary).month() + 1 === 12) {
                                    MarriageMonth = 'Dec'
                                }

                                // ==================================== birth day month==================================
                                if (moment(member.birthAnniversary).month() + 1 === 1) {
                                    BirthdayMonth = 'Jan'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 2) {
                                    BirthdayMonth = 'Feb'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 3) {
                                    BirthdayMonth = 'Mar'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 4) {
                                    BirthdayMonth = 'Apr'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 5) {
                                    BirthdayMonth = 'May'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 6) {
                                    BirthdayMonth = 'Jun'
                                }

                                if (moment(member.birthAnniversary).month() + 1 === 7) {
                                    BirthdayMonth = 'Jul'
                                }

                                if (moment(member.birthAnniversary).month() + 1 === 8) {
                                    BirthdayMonth = 'Aug'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 9) {
                                    BirthdayMonth = 'Sep'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 10) {
                                    BirthdayMonth = 'Oct'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 11) {
                                    BirthdayMonth = 'Nov'
                                }
                                if (moment(member.birthAnniversary).month() + 1 === 12) {
                                    BirthdayMonth = 'Dec'
                                }

                                return (
                                    <div className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto'>
                                        <div className='col-lg-12 home-p-box_member pb-3 p-3 district_card_item mx-auto ' >
                                            <center>
                                                <img src={logo} width='150px' height="150px" className='mt-3' />
                                            </center>
                                            <br />
                                            <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>
                                            <div key={index} >
                                                <div style={{ marginLeft: 10 }}>
                                                    <p>{member.membershipNo}</p>
                                                    <p style={{ fontSize: 15 }}>{member.membershipName}</p>
                                                    <p style={{ color: '#297af5' }}>{member.mobileNumber}</p>

                                                    <p>{
                                                        `BA :${moment(member.birthAnniversary).date()} ${BirthdayMonth}`

                                                    }</p>
                                                    <p>{
                                                        `MA :${moment(member.marriageAnniversary).date()} ${MarriageMonth}`

                                                    }</p>
                                                    <p style={{ fontSize: 14 }}>{member.email}</p>
                                                    <p style={{ fontSize: 14 }}>{member.mobile}</p>
                                                     <a href={`https://api.whatsapp.com/send?phone=+91${member.mobileNumber}&text=Hi.`} target="_blank">
                                                     <center>
                                                        <FaWhatsapp style={{ color: 'green', fontSize: 25, textAlign: 'center' }} />
                                                    </center>
                                                     </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                )
            }
        </div>
    )
}

export default Member