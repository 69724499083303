// import React, { useEffect } from 'react'
// import axios from 'axios'
// import { useState } from 'react'
// import logo from '../image/icon.png'

// const International = () => {

//     const [international, setInternationalData] = useState([])

//     const internationalShow = (route) => {
//         axios.get('https://lions320dapi.emedha.in/international/get', {
//             route
//         }).then((res) => {
//             setInternationalData(res.data)
//         })
//     }

//     useEffect(() => {
//         internationalShow(sessionStorage.getItem('route'))
//     }, [])
// console.log(international)
//     return (
//         <div className='container-fluid mb-1 mt-3' >
//             <div className='row gy-2' >
//                 {
//                     international.map((intl) => {
//                         return (
//                             <div key={intl.id} className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto'>
//                                 <div className='col-lg-12 home-p-box_international  pb-3 district_card_item mx-auto p-3' >
//                                     <center>
//                                         <img src={logo} width='140px' height="140px" className='mt-3' />
//                                     </center>
//                                     <br />
//                                     <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }} ></div>
//                                     <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>{intl.name}</p>
//                                     <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.position}</p>
//                                     <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.email}</p>
//                                     <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.description}</p>
//                                 </div>
//                             </div>
//                         )
//                     })
//                 }
//             </div>
//         </div>
//     )
// }

// export default International



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../image/icon.png';

const International = () => {
    const [international, setInternationalData] = useState([]);
    const [filteredInternational, setFilteredInternational] = useState([]);
    const selectedPosition = sessionStorage.getItem('route'); // Get the filter value from sessionStorage

    useEffect(() => {
        axios.get('https://lions320dapi.emedha.in/international/get')
            .then((res) => {
                setInternationalData(res.data);
            });
    }, []);

    useEffect(() => {
        if (selectedPosition) {
            // Filter based on the selected position
            const filteredData = international.filter(item => item.position === selectedPosition);
            setFilteredInternational(filteredData);
        } else {
            setFilteredInternational(international); 
        }
    }, [international, selectedPosition]);

    return (
        <div className='container-fluid mb-1 mt-3'>
            <div className='row gy-2'>
                {filteredInternational.map((intl) => (
                    <div key={intl.id} className='col-lg-4 col-12 col-xl-4 col-xxl-3 col-md-6 mx-auto'>
                        <div className='col-lg-12 home-p-box_international pb-3 district_card_item mx-auto p-3'>
                            <center>
                                <img src={logo} width='140px' height="140px" className='mt-3' />
                            </center>
                            <br />
                            <div style={{ borderBottom: '3px solid rgb(44, 114, 243)' }}></div>
                            <p style={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>{intl.name}</p>
                            <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.position}</p>
                            <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.email}</p>
                            <p style={{ fontSize: 17, alignItems: 'center', fontWeight: 'bold' }}>{intl.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default International;
